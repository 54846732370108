import { useContext } from "react";
import { useHistory, useNavigate } from "react-router";
import {
  ACCOUNT_LINK_INITIATE,
  ACCOUNT_LINK_INITIATED,
  PLATFORM_POPUP_CLOSE,
  PLATFORM_POPUP_OPEN,
} from "../../analytics/eventConstants";
import { trackEvent } from "../../analytics/sdkAnalytics";
import {
  SOMETHING_WENT_WRONG,
  STATUS_CONNECTED,
  STATUS_ERROR_INFO,
  STATUS_FAILED,
  STATUS_INFO_REQUIRED,
} from "../../constants/URLConstants";
import {
  INSTA_DIRECT_WORKPLATFORM_ID,
  PHYLLO_ACCOUNT_CONNECTED_KEY,
  PHYLLO_ON_CONNECTION_FAILURE,
} from "../../constants/constants";
import { SDKInstanceState } from "../../context/SdkInstanceContext";
import { sendingAccountConnectedCallback, versionCompare } from "../sdk_utils";
import { LoaderWithStatusState } from "../../context/LoaderWithStatusContext";
import { LoaderState } from "../../context/LoaderContext";

export const useGetRedirectAuthFlow = (
  workplatformId,
  workplatformName,
  sdkParams,
  workplatformDetails
) => {
  const { sdkInstance, dispatchSdkInstContext } = SDKInstanceState();
  const { isConnectLoader, setIsConnectLoader, setWindowPopup } =
    LoaderWithStatusState();
  const { loader, dispatchLoaderContext } = LoaderState();
  const navigate = useNavigate();
  let timer;
  if (workplatformId) {
    // Checking for v1 version & check for redirect flow
    if (sdkParams.version === "1" || sdkParams.redirectURL !== "null") {
      return async () => {
        try {
          // saving context to session Storage
          sessionStorage.setItem("sdkParams", JSON.stringify(sdkParams));
          sessionStorage.setItem(
            "workplatformDetails",
            JSON.stringify(workplatformDetails)
          );

          const queryParamStr = `?version=${sdkParams.version}&redirectURL=${sdkParams.redirectURL}&userId=${sdkParams.userId}&appName=${sdkParams.appName}&workplatformId=${workplatformId}&workplatformName=${workplatformName}`;
          const redirectURLPostOAuth =
            process.env.REACT_APP_REDIRECT_URL + queryParamStr;
          const response = await sdkInstance.getAuthorizations(
            workplatformId,
            redirectURLPostOAuth
          );
          if (Object.keys(response).length === 0)
            navigate(`/${SOMETHING_WENT_WRONG}`);
          else window.location.href = response.authorization_url;

          // Tracking event : ACCOUNT_LINK_INITIATE
          trackEvent(ACCOUNT_LINK_INITIATE, {
            work_platform_id: workplatformId,
            work_platform_name: workplatformName,
            account_connection_type: "New_Connection",
            oauth_url: response.authorization_url,
          });
        } catch (err) {
          console.log(err);
          throw err;
        }
      };
    }

    // Popup flow
    return async () => {
      try {
        setIsConnectLoader(true);
        dispatchLoaderContext(true);

        let windowPopup = "";
        let redirectURLPostOAuth = "";
        const popupHeight = window.innerHeight / 1.33;
        const popupWidth = window.innerWidth / 1.33;
        let timer;
        if (!JSON.parse(sdkParams.mobile)) {
          windowPopup = window.open(
            "",
            "",
            `popup,resizeable,width=${popupWidth},height=${popupHeight},top=${
              popupHeight / 3
            },left=${popupWidth / 3.5}`
          );
          if (windowPopup === null || windowPopup === "undefined") {
            windowPopup = window.open(
              "",
              "_blank",
              `top=${popupHeight / 3},left=${popupWidth / 3.5}`
            );
          }
          const queryParamStr = `?version=${sdkParams.version}&redirectURL=${sdkParams.redirectURL}&userId=${sdkParams.userId}&appName=${sdkParams.appName}&workplatformId=${workplatformId}&workplatformName=${workplatformName}`;
          redirectURLPostOAuth =
            process.env.REACT_APP_REDIRECT_URL + queryParamStr;
        } else redirectURLPostOAuth = "phyllo://auth";

        const response = await sdkInstance.getAuthorizations(
          workplatformId,
          redirectURLPostOAuth
        );

        if (Object.keys(response).length === 0) {
          navigate(`/${SOMETHING_WENT_WRONG}`);
          return;
        }

        const eventHandlerCallback = (event) => {
          // To check if no other app is interfering with Phyllo SDK callback
          dispatchLoaderContext(true);
          if (
            event.origin.indexOf(process.env.REACT_APP_PHYLLO_DOMAIN) === -1 ||
            !event.data.status ||
            !event.data.authorization_id
          )
            return;
          window.removeEventListener("message", eventHandlerCallback, false);
          const responseFromEvent = event.data;
          clearInterval(timer);
          if (responseFromEvent && responseFromEvent.status === "success") {
            if (responseFromEvent.account_ids) {
              //   // Sending onAccountConnected callback
              //   sendingAccountConnectedCallback(responseFromEvent.account_ids.split(","), workplatformId, sdkParams);

              navigate(`/${STATUS_CONNECTED}/${responseFromEvent.account_ids}`);
              setIsConnectLoader(false);
            } else {
              navigate(`/${SOMETHING_WENT_WRONG}`);
              setIsConnectLoader(false);
              dispatchLoaderContext(false);
            }
          } else if (responseFromEvent.status === "info_required") {
            navigate(
              `/${STATUS_INFO_REQUIRED}/${responseFromEvent?.authorization_id}/`
            );
          } else if (responseFromEvent.status === "failed_info") {
            dispatchLoaderContext(true);
            navigate(
              `/${STATUS_ERROR_INFO}/${responseFromEvent.authorization_id}/`,
              {
                state: {
                  authorization_id: responseFromEvent.authorization_id,
                  error_message: responseFromEvent.error_message,
                  error_code: responseFromEvent.error_code,
                },
              }
            );
            setIsConnectLoader(false);
          } else {
            setIsConnectLoader(false);
            navigate(`/${STATUS_FAILED}/${responseFromEvent.authorization_id}`);
          }
        };

        const urlToOpen = response.authorization_url;
        setWindowPopup(windowPopup);
        if (!JSON.parse(sdkParams.mobile)) {
          windowPopup.location = urlToOpen;

          // Tracking event : PLATFORM_POPUP_OPEN
          trackEvent(PLATFORM_POPUP_OPEN, {
            work_platform_id: workplatformId,
            work_platform_name: workplatformName,
            popup_open_url: urlToOpen,
          });

          timer = setInterval(() => {
            if (windowPopup.closed && workplatformId !== INSTA_DIRECT_WORKPLATFORM_ID) {
              // Tracking event : PLATFORM_POPUP_CLOSE
              windowPopup.close();

              window.removeEventListener(
                "message",
                eventHandlerCallback,
                false
              );
              dispatchLoaderContext(true);
              setIsConnectLoader(false);
              clearInterval(timer);
              navigate(`/${STATUS_FAILED}/${response.id}`);
              try {
                trackEvent(PLATFORM_POPUP_CLOSE, {
                  popup_close_url: windowPopup?.location?.href,
                  initiator: "user",
                  work_platform_id: workplatformId,
                  work_platform_name: workplatformName,
                  reason: "user has abandoned the connection flow",
                });
              } catch (err) {
                trackEvent(PLATFORM_POPUP_CLOSE, {
                  popup_close_url: "Fetching URL not supported in Safari",
                  initiator: "user",
                  work_platform_id: workplatformId,
                  work_platform_name: workplatformName,
                  reason: "user has abandoned the connection flow",
                });
              }
            }
          }, 1500);

          window.addEventListener("message", eventHandlerCallback, false);
        } else {
          // This event listener is for web-view on mobile
          window.addEventListener("message", eventHandlerCallback, false);
          if (
            sdkParams.mobileVersion !== "null" &&
            versionCompare(sdkParams.mobileVersion, "0.3.1") >= 0
          ) {
            // Checking version and sending the workplatform details for analytics //Maintaining backward compatibility
            window.location.href = `${redirectURLPostOAuth}?oAuthId=${response.id}&workplatformId=${workplatformId}&workplatformName=${workplatformName}&oAuthURL=${urlToOpen}`;
          } else
            window.location.href = `${redirectURLPostOAuth}?oAuthURL=${urlToOpen}?oAuthId=${response.id}`;
        }
        // Tracking event : ACCOUNT_LINK_INITIATE
        trackEvent(ACCOUNT_LINK_INITIATE, {
          work_platform_id: workplatformId,
          work_platform_name: workplatformName,
          account_connection_type: "New_Connection",
          oauth_url: urlToOpen,
        });
      } catch (err) {
        console.log(err);
        throw err;
      }
    };
  }
};
